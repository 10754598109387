<template>
    <div v-if="!$root.filterWithUrl" class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/ecommerce/user" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.ecommerce.user.title") }}
        </router-link>
    </div>
    <UserAddress :userID="userID"></UserAddress>
</template>

<script>
import UserAddress from "@/components/ecommerce/user/address";

export default {
    name: "_id",
    components: {
        UserAddress
    },
    data() {
        return {}
    },
    computed: {
        userID() {
            return this.$route.params.id
        }
    },
    created() {
        if (this.userID && !(this.userID > 0)) {
            this.$router.push({
                path: "/ecommerce/user"
            });
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.ecommerce.user.address.title"), [this.$t("menu.ecommerceManagement"), this.$t("menu.users")]);
    },
    methods: {}
}
</script>

<style scoped>

</style>